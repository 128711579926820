.profile-image-container {
  min-width: 130px;
  max-width: 130px;
  height: 140px !important;
  padding-right: 0 !important;
}

.profile-image {
  height: 130px;
  width: 130px;
  max-width: none !important;
}

.profile-image img {
  height: 100% !important;
  width: 100% !important;
  object-fit: cover;
  background-position: center;
}

.profile-image:hover {
  cursor: pointer;
}

.profile-image-add-button {
  width: 30px;
  height: 30px;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  position: relative !important;
  float: right !important;
  top: -35px !important;
  right: -15px !important;
  border-radius: 25px !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1) !important;
  background-color: #1b8f45 !important;
  color: #ffffff !important;
}

.profile-image-add-button .profile-image-add-button-icon {
  display: flex;
  align-items: center;
  height: 15px !important;
  width: 15px !important;
  background-color: transparent !important;
}

.profile-name {
  font-size: 48px !important;
  font-weight: bold !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.29 !important;
  letter-spacing: 1.03px !important;
  color: #000 !important;
  margin-left: 5% !important;
  margin-bottom: 0 !important;
  margin-top: 5px !important;
}

.profile-edit-text {
  font-size: 24px !important;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: 0.51px !important;
  color: #000 !important;
  margin-left: 5% !important;
  margin-top: 0 !important;
}

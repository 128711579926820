.background {
  height: 100% !important;
  min-height: 100vh !important;
  width: 100vw !important;
  max-width: 100vw !important;
  background-image: url("../../assets/Images/background-login.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

#root .ui.container .text-container {
  margin-top: 54px;
  margin-left: auto !important;
  margin-right: auto !important;
  line-height: normal;
  font-size: 54px;
  font-weight: bold;
  color: #fff;
}

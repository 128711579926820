.register-info-title {
  max-width: 560px !important;
  width: 100% !important;
  font-size: 54px !important;
  font-weight: bold !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.15 !important;
  letter-spacing: 1.16px !important;
  color: #ffffff !important;
}

.register-info-facebook-container {
  padding-top: 10%;
}

@media only screen and (max-width: 600px) {
  .register-info-title {
    font-size: 35px !important;
    padding-top: 10% !important;
  }
}

@media only screen and (max-width: 400px) {
  .register-info-title {
    font-size: 30px !important;
  }
}

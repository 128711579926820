.dashboard-divider {
  background-color: #32bcad !important;
  border: 1.3px solid #32bcad !important;
  margin: 1% 0 2% 0 !important;
}

.dashboard-close-button {
  width: 130px !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: 0.47px !important;
  color: #000000 !important;
  background-color: transparent !important;
}

.dashboard-close-button .dashboard-close-button-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 1.9em !important;
  transform: rotate(180deg);
  background-color: transparent !important;
}

.dashboard-title {
  font-size: 42px !important;
  font-weight: bold !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.24 !important;
  letter-spacing: 0.9px !important;
  color: var(--principal-color-page) !important;
  margin: 1% 0 0 0 !important;
}

.dashboard-sub-title-1 {
  font-size: 18px !important;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: 0.39px !important;
  color: #000000 !important;
  margin: 0 !important;
}

.dashboard-sub-title {
  font-size: 18px !important;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: 0.39px !important;
  color: #000000 !important;
  margin-top: 4% !important;
}

@media only screen and (max-width: 767px) {
  .dashboard-close-button {
    width: 120px !important;
    font-size: 20px !important;
    margin-top: 0 !important;
  }

  .dashboard-close-button .dashboard-close-button-icon {
    font-size: 14px !important;
  }
}

@media only screen and (max-width: 400px) {
  .dashboard-close-button {
    font-size: 18px !important;
  }

  .dashboard-close-button .dashboard-close-button-icon {
    font-size: 13px !important;
  }
}

.login-info-title {
  max-width: 398px;
  width: 160% !important;
  font-size: 48px !important;
  font-weight: bold !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.25 !important;
  letter-spacing: 1.03px !important;
  color: #ffffff !important;
}

.login-info-sub-title {
  font-size: 18px !important;
  font-weight: 600 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: 0.39px !important;
  color: #ffffff !important;
  background-color: transparent !important;
  padding-top: 10% !important;
  padding-bottom: 8% !important;
}

.login-button-register {
  width: 100%;
  max-width: 300px;
  height: 60px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 30px !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1) !important;
  background-color: #e4dcca !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.13 !important;
  letter-spacing: normal !important;
  color: #1b8f45 !important;
}

.login-info-business-icon {
  padding-right: 15px;
}

.login-info-arrow-right-icon {
  padding-left: 15px;
}

@media only screen and (max-width: 600px) {
  .login-info-title {
    font-size: 35px !important;
  }

  .login-info-sub-title {
    font-size: 16px !important;
  }

  .login-button-register {
    margin-left: 0 !important;
  }

  .login-logo {
    height: 80px !important;
  }
}

@media only screen and (max-width: 400px) {
  .login-info-title {
    font-size: 28px !important;
  }

  .login-logo {
    height: 70px !important;
  }
}

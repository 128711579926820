.password-info-title {
  max-width: 382px !important;
  width: 100% !important;
  font-size: 54px !important;
  font-weight: bold !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.15 !important;
  letter-spacing: 1.16px !important;
  color: #ffffff !important;
}

@media only screen and (max-width: 600px) {
  .password-info-title {
    font-size: 44px !important;
  }
}

:root {
  --font-family-poppins: "Poppins", sans-serif;
  --principal-color-page: #32bcad;
}

/*
Override the font family for the whole page, but not for icons.
*/
* :not(i.icon) {
  font-family: var(--font-family-poppins) !important;
}

#root .ui.container {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

#root .ui.grid {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.button {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
}

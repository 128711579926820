.form-login-forgot-password-email {
  width: 80%;
}

.title-form-login-forgot-password-email {
  font-size: 42px !important;
  font-weight: bold !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.1 !important;
  letter-spacing: 0.9px !important;
  color: var(--principal-color-page) !important;
  margin-bottom: 0 !important;
}

.sub-title-form-login-forgot-password-email {
  font-size: 14px !important;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.29 !important;
  letter-spacing: 0.5px !important;
  color: #000000 !important;
  margin: 20px 0 !important;
}

.login-forgot-email-footer-container {
  padding-top: 1% !important;
}

.login-forgot-email-back-login {
  font-size: 16px !important;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.13 !important;
  letter-spacing: normal !important;
  text-align: center !important;
  color: var(--principal-color-page) !important;
  -webkit-text-decoration-line: underline !important;
  text-decoration-line: underline !important;
  padding-top: 10px !important;
  float: right;
}

.login-forgot-email-container-email-button {
  padding-top: 20px !important;
}

.login-forgot-email-button-complete .login-forgot-email-icon-button {
  color: #ffffff;
}

.form-login {
  width: 80%;
}

.title-form-login {
  font-size: 42px !important;
  font-weight: bold !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.24 !important;
  letter-spacing: normal !important;
  color: var(--principal-color-page) !important;
  padding-bottom: 5% !important;
}

.container-login-button {
  padding-top: 10px !important;
}

.login-check-list {
  padding-top: 2% !important;
}

.login-check-item {
  font-size: 14px !important;
  font-weight: 300 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.29 !important;
  letter-spacing: 0.5px !important;
  color: #000000 !important;
  margin: 5px !important;
}

.login-check-item i {
  vertical-align: bottom !important;
  padding-top: 20px;
}

.login-link-row {
  padding-top: 3% !important;
}

.login-link {
  font-size: 16px !important;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  margin: 8px 0 !important;
  line-height: 1.13 !important;
  letter-spacing: normal !important;
  text-align: center !important;
  color: var(--principal-color-page) !important;
  -webkit-text-decoration-line: underline !important;
  text-decoration-line: underline !important;
  float: right;
}

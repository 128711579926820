.row-register-stepper {
  margin-top: 5% !important;
}

.register-stepper-icon {
  height: 10px;
  width: 52px;
  color: #00c0ad;
  margin: 0 5px !important;
}

.row-register-stepper i.disabled.icon {
  color: #b9c4ce !important;
  opacity: 1 !important;
}

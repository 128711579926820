.container-background {
  max-width: 100vw !important;
  width: 100vw !important;
  height: 100% !important;
  background-color: #464646;
}

.background-container-login {
  height: 100% !important;
  min-height: 100vh !important;
  width: 100vw !important;
  max-width: 100vw !important;
  background-image: url("../../../assets/Images/background-login.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.login-container {
  height: 100% !important;
  display: flex;
  justify-content: space-around !important;
  align-items: center !important;
  padding: 10vh 8% !important;
}

@media only screen and (max-width: 440px) {
  .login-container {
    padding: 0% !important;
  }
}

.login-forgot-password-data-container {
  width: 430px;
  min-height: 440px !important;
  height: 100% !important;
  border-radius: 20px !important;
  background-color: #eee4cf !important;
  box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.1) !important;
  display: flex;
  align-self: flex-end;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: column !important;
  padding: 10% 0 !important;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-bottom: 10% !important;
  margin-top: 5% !important;
}

@media only screen and (min-width: 992px) {
  .login-forgot-password-data-container {
    float: right;
  }
}

@media only screen and (max-width: 540px) {
  .login-forgot-password-data-container {
    width: 100%;
  }
}

.login-button-register-fb {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 100% !important;
  max-width: 240px !important;
  height: 60px !important;
  border-radius: 30px !important;
  background-color: #306ed5 !important;
  border: none !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.13 !important;
  letter-spacing: normal !important;
  text-align: center !important;
  color: #ffffff !important;
}

.login-button-register-fb:hover {
  cursor: pointer;
}

.login-button-register-fb::after {
  content: url("../../assets/Components/arrow-right-white.png");
  padding-left: 20px !important;
  padding-right: 15px !important;
}

.login-button-register-fb i {
  color: #ffffff !important;
  opacity: 1 !important;
  padding-right: 15px !important;
  padding-left: 15px !important;
}

.login-button-register-fb:focus {
  border: none !important;
  outline: none !important;
}

.experience-location-div {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 16px;
  padding-left: 0;
  padding-right: 0;
}

.experience-location-map-div {
  flex: 1;
}

.experience-location-vertical-space-div {
  margin-top: 16px;
}

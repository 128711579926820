.form-experience-radio-container {
  margin: 15px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.form-experience-radio {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: white;
  border: solid 2px #00c0ad;
  width: 22px;
  height: 21px;
  border-radius: 3px;
  outline: none;
}

.form-experience-radio-label {
  font-size: 12px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.5 !important;
  letter-spacing: normal !important;
  color: #000000 !important;
  margin-left: 8px;
}

.form-experience-radio:checked {
  background: #00c0ad;
  position: relative;
  display: inline-block;
}

.form-experience-radio:checked::before {
  position: absolute;
  left: 1px;
  top: 32%;
  height: 50%;
  width: 2.5px;
  background-color: #ffffff;
  content: "";
  transform: translateX(6px) rotate(-45deg);
  transform-origin: left bottom;
  border-radius: 1.5px 1.5px 0 1.5px !important;
}

.form-experience-radio:checked::after {
  position: absolute;
  left: 1px;
  bottom: 3.1px;
  height: 2.5px;
  width: 78%;
  background-color: #ffffff;
  content: "";
  transform: translateX(6px) rotate(-45deg);
  transform-origin: left bottom;
  border-radius: 0 1.5px 1.5px 1.5px !important;
}

.form-input svg {
  height: 100% !important;
  vertical-align: middle !important;
}

.form-input input {
  height: 60px;
  border-radius: 10px !important;
  background-color: #ffffff !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.29 !important;
  letter-spacing: 0.5px !important;
  color: #000000 !important;
}

.form-input input::-webkit-input-placeholder {
  font-weight: 300 !important;
}

.form-input input::-moz-placeholder {
  font-weight: 300 !important;
}

.form-input input:-ms-input-placeholder {
  font-weight: 300 !important;
}

.form-input input:-moz-placeholder {
  font-weight: 300 !important;
}

.form-input .ui[class*="left icon"].input > input {
  padding-left: 60px !important;
}

.form-input i {
  left: 9px !important;
}

.form-input-error {
  font-size: 14px !important;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.29 !important;
  letter-spacing: 0.5px !important;
  color: #e54e26 !important;
  padding-top: 5px !important;
}

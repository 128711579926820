.MuiSlider-rail,
.MuiSlider-track,
.MuiSlider-mark {
  height: 5px !important;
}

.form-experience-range-double .MuiSlider-markLabel {
  top: -10px !important;
  font-size: 12px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.5 !important;
  letter-spacing: normal !important;
  color: #000000 !important;
}

/* .form-experience-range-double .MuiSlider-markLabel[data-index='0'] {
    left: 16px !important;
}

.form-experience-range-double .MuiSlider-markLabel[data-index='3'] {
    right: -36px !important;
} */

.form-experience-range-double,
.MuiSlider-marked {
  margin: 12px 0 0 0 !important;
  padding-top: 20px !important;
}

.experience-form-header {
  font-size: 14px !important;
  font-weight: 600 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.29 !important;
  letter-spacing: 0.5px !important;
  color: #000000 !important;
  text-transform: uppercase !important;
}

.experience-form-divider {
  background-color: #e78db3 !important;
  border: 2px solid #e78db3 !important;
}

.experience-form-active-hours-header {
  font-size: 12px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.5 !important;
  letter-spacing: normal !important;
  color: #000000 !important;
  margin: -8px 0 -20px 0 !important;
}

.experience-form-feature-label {
  margin: 4px !important;
}

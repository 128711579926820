.user-profile-image-message-card {
  width: 386px !important;
  border-radius: 20px !important;
  background-color: #ffffff !important;
  top: 5% !important;
  padding: 3.5% 3.8% !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.user-profile-image-message-title {
  font-size: 32px !important;
  font-weight: bold !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.13 !important;
  letter-spacing: 0.69px !important;
  color: #e54e26 !important;
  padding-bottom: 6% !important;
}

.user-profile-image-message-sub-title {
  font-size: 18px !important;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: 0.39px !important;
  color: #000000 !important;
  padding-bottom: 8% !important;
}

.user-profile-image-message-button {
  width: 100% !important;
  height: 60px !important;
  border-radius: 30px !important;
  background-color: #1b8f45 !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1 !important;
  letter-spacing: normal !important;
  text-align: center !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15) !important;
  color: #fff !important;
}

.user-profile-image-message-button .user-profile-image-message-button-icon {
  background-color: transparent !important;
  display: flex !important;
  align-items: center !important;
}

.user-profile-image-message-back-button {
  width: 100px;
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-top: 5% !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.29 !important;
  letter-spacing: 0.5px !important;
  text-align: center !important;
  color: #000000 !important;
  background-color: transparent !important;
  text-decoration: underline;
}

.user-profile-image-message-back-button:hover {
  cursor: pointer;
}

.user-profile-image-message-image-container {
  display: block;
  margin-left: auto !important;
  margin-right: auto !important;
  min-width: 200px;
  max-width: 200px;
  height: 200px !important;
  padding-right: 0 !important;
}

.user-profile-image-message-image {
  height: 200px;
  width: 200px;
  max-width: none !important;
}

.user-profile-image-message-image img {
  height: 100% !important;
  width: 100% !important;
  object-fit: cover;
  background-position: center;
}

.user-profile-image-message-image:hover {
  cursor: pointer;
}

.user-profile-image-message-image-add-button {
  width: 50px;
  height: 50px;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  position: relative !important;
  float: right !important;
  top: -50px !important;
  right: 0 !important;
  border-radius: 25px !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1) !important;
  background-color: #1b8f45 !important;
  color: #ffffff !important;
}

.user-profile-image-message-image-add-button .user-profile-image-message-image-add-button-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100% !important;
  width: 100% !important;
  background-color: transparent !important;
}

@media only screen and (max-width: 450px) {
  .user-profile-image-message-card {
    width: 90% !important;
    padding: 10% 9.5% !important;
  }

  .user-profile-image-message-button {
    height: 50px !important;
  }
}

.block-account-container {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  height: 100vh !important;
  width: 100vw !important;
  background: transparent !important;
  z-index: 1000 !important;
}

.block-account-card {
  width: 429px;
  max-height: 412px;
  border-radius: 20px !important;
  background-color: #ffffff !important;
  left: calc(50% - 215px) !important;
  top: calc(50% - 206px) !important;
  padding: 3.5% 3.8% !important;
}

.block-account-title {
  font-size: 32px !important;
  font-weight: bold !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.13 !important;
  letter-spacing: 0.69px !important;
  color: #e54e26 !important;
  padding-bottom: 6% !important;
}

.block-account-sub-title {
  font-size: 18px !important;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: 0.39px !important;
  color: #000000 !important;
  padding-bottom: 14% !important;
}

.block-account-button {
  width: 100% !important;
  height: 60px !important;
  border-radius: 35px !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.13 !important;
  letter-spacing: normal !important;
  background-color: #1b8f45 !important;
  color: #ffffff !important;
}

@media only screen and (max-width: 450px) {
  .block-account-card {
    width: 300px !important;
    left: calc(50% - 150px) !important;
    top: calc(15%) !important;
    padding: 10% 9.5% !important;
  }

  .block-account-button {
    width: 175px !important;
    height: 50px !important;
  }
}

.container-background-password {
  max-width: 100vw !important;
  width: 100vw !important;
  height: 100% !important;
  background-color: #464646;
}

.background-container-password {
  height: 100% !important;
  min-height: 100vh !important;
  width: 100vw !important;
  max-width: 100vw !important;
  background-image: url("../../../assets/Images/background-login.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.password-container {
  height: 100% !important;
  display: flex;
  justify-content: space-around !important;
  align-items: center !important;
  padding: 5vh 9% !important;
}

.password-container-info {
  height: 100%;
  align-self: flex-start !important;
  padding-top: 12.2% !important;
}

@media only screen and (max-width: 440px) {
  .password-container {
    padding: 0% !important;
  }
}

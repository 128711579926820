.form-login-forgot-password-reset {
  width: 80%;
}

.title-form-login-forgot-password-reset {
  max-width: 320px !important;
  font-size: 42px !important;
  font-weight: bold !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.1 !important;
  letter-spacing: 0.9px !important;
  color: var(--principal-color-page) !important;
}

.login-forgot-password-container-password-button {
  padding-top: 15px !important;
}

.login-forgot-password-check-list {
  padding-top: 2% !important;
}

.login-forgot-password-check-item {
  font-size: 14px !important;
  font-weight: 300 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.29 !important;
  letter-spacing: 0.5px !important;
  color: #000000 !important;
  margin: 5px !important;
}

.login-forgot-password-check-item i {
  vertical-align: bottom !important;
  padding-top: 20px;
}

.login-forgot-password-privacy-policy {
  font-size: 12px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.5 !important;
  letter-spacing: normal !important;
  color: #000000 !important;
  margin: 5% 3% !important;
}

.login-forgot-password-privacy-policy label {
  padding-left: 30px !important;
}

.login-forgot-password-privacy-policy label::before {
  width: 19px !important;
  height: 18px !important;
  background: transparent !important;
  border-radius: 3px !important;
  border: solid 2px #00c0ad !important;
}

.login-forgot-password-privacy-policy label::after {
  width: 19px !important;
  height: 18px !important;
  border-radius: 3px !important;
  background: #00c0ad !important;
  color: #ffffff !important;
}

.menu-container {
  height: 80px;
  top: 0;
  z-index: 1000 !important;
  border: none !important;
  box-shadow: none !important;
  padding: 20px 82px !important;
  border-radius: 0 !important;
  margin: 0 !important;
  background-color: transparent !important;
  background-position: left top !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  position: sticky !important;
}

.menu-container-logo {
  padding: 0 !important;
  width: auto !important;
}

.menu-container-logo::before {
  content: none !important;
}

.white-logo {
  width: 50px;
  height: 50px;
  object-fit: contain;
}

.menu-container-right {
  padding: 0 !important;
}

.menu-container-right::before {
  content: none !important;
}

.menu-container-login-button {
  height: 45px;
  width: 140px;
  border-radius: 30px !important;
  background-color: #e54e26 !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.13 !important;
  letter-spacing: normal !important;
  text-align: center !important;
  color: #ffffff !important;
  right: 8% !important;
}

.menu-container-login-button-icon {
  margin-right: 8px !important;
}

.menu-container-toggle-button {
  height: 45px !important;
  width: 45px !important;
  padding: 0 !important;
  border-radius: 50% !important;
  background-color: #ffffff !important;
  box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.2) !important;
}

.menu-container-toggle-button i {
  height: 100% !important;
  margin: 0 !important;
  color: #000000;
}

.menu-container-toggle-button-icon {
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 440px) {
  .menu-container {
    padding: 20px 30px !important;
  }
}

@media only screen and (max-width: 1000px) {
  .menu-container-logo img {
    content: url("../../assets/Logos/green-logo.png");
  }
}
